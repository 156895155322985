export function getEmployerUrl(employer, toCareerSite = true) {
  const config = useRuntimeConfig()

  if (toCareerSite && employer.careerSite?.active) {
    return `https://${config.public.careerDomain}/${employer.careerSite.slug}`
  }
  return `/employeurs/${employer.slug}`
}

export function getJobUrl(job) {
  return `/jobs/${job.slug}`
}
